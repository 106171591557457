<template>
  <div>
    <div style="height: 100vh" class="container-fluid">
      <div class="row no-gutters">
        <div class="col-lg-5">
          <div class="textD">
            <h1>SREP</h1>
            <p>Appmart Staff Report.</p>
          </div>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
          <div class="regDiv">
            <div class="formDiv">
              <h3>Forgot password</h3>
              <div class="inputs">
                <div class="">
                  <label class="label-f">Email address</label>
                  <v-otp-input
                    length="4"
                    v-model="otp"
                    :disabled="loading"
                  ></v-otp-input>
                </div>
                <div class="mt-3">
                  <button @click="forgotPassword" class="createBtn">
                    Continue
                  </button>
                </div>
                <!-- <div class="mb-3">
                                    <button @click="forgotPass" class="signIn">Forgot Password?</button>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      baseUrl: process.env.VUE_APP_SREP_BASE_URL,
      loading: false,
      snackbar: false,
      snackbarColor: "default",
      userId: "",
      otp: "",
      text: "",
    };
  },
  async mounted() {
    const retrievedData = localStorage.getItem("userEmail");
    const userEmail = JSON.parse(retrievedData);

    await axios
      .get(`${this.baseUrl}users/getUserEmail/${userEmail}`)
      .then((response) => {
        this.userId = response.data.user._id;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    onFinish(rsp) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.snackbarColor = rsp === this.expectedOtp ? "success" : "warning";
        this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`;
        this.snackbar = true;
      }, 3500);
    },
    signIn() {
      this.$router.push("/signIn");
    },
    async forgotPassword() {
      if (!this.otp) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Check your Email Address for the OTP",
          width: 350,
        });
      } else {
        const logindata = {
          otp: this.otp,
        };
        await axios
          .post(`${this.baseUrl}auth/verifyOtp/${this.userId}`, logindata, {
            headers: {
              api_key: this.apiKey,
            },
          })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Proceed",
              showConfirmButton: false,
              text: response.data.message,
              width: 350,
              timer: 1400,
            });
            localStorage.setItem("userId", this.userId);
            setTimeout(() => {
              this.$router.push("/resetPassword");
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Not Successful",
              width: 350,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Caveat");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap");

* {
  margin: 0%;
  padding: 0%;
}

.container-fluid {
  background: url("../../assets/dashboard svg.svg");
  // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../../assets/landImg.jpg);
  // background-position: center;
  // background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  // height: 100vh;
  // background-color: #0C4D6A;
}

.lightLogo {
  display: block;
}

.darkLogo {
  display: none;
}

.logoDiv {
  position: fixed;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;

  .logoTxt {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    justify-content: center;
    align-items: flex-end;

    h4 {
      margin: 0%;
      color: #fff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
    }

    p {
      margin: 0%;
      color: #fff;
      font-family: "Caveat";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
    }
  }
}

.textD {
  padding-left: 5rem;
  margin: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    font-family: "Rubik";
    font-style: normal;
    line-height: 76px;
    font-weight: 800;
    font-size: 108px;
    // margin-bottom: 2rem;
    color: #ffffff;
  }

  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    // line-height: 111px;
    color: #ffffff;
  }
}

.regDiv {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  .formDiv {
    border-radius: 8px;
    background-color: #ffffff;
    width: 80%;
    height: auto;
    padding: 2rem;
    margin: 0%;

    h3 {
      margin-bottom: 30px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      // line-height: 30px;
      /* identical to box height */
      text-align: center;
      color: #0c4d6a;
    }

    .inputs {
      // margin-top: 2rem;

      .label-f {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        margin-bottom: 14px;
        /* identical to box height */
        color: #0c4d6a;
      }

      .textField {
        background-color: #fdfdfd;
        border: 1px solid #dfdbdb;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        padding: 0 1rem;
      }

      .createBtn {
        border-radius: 5px;
        background-color: #0c4d6a;
        width: 40%;
        height: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 2rem;
        color: #ffffff;
      }

      .signIn {
        color: #0c4d6a;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        margin-left: 2px;
      }

      .alrdy {
        color: grey;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1200px) {
  .lightLogo {
    display: none;
  }

  .darkLogo {
    display: block;
    width: 20%;
  }

  .logoDiv {
    width: 100%;
    position: fixed;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: #fdfdfd;
    margin: 0%;

    .logoTxt {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      justify-content: center;
      align-items: flex-end;

      h4 {
        margin: 0%;
        color: #000;
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
      }

      p {
        margin: 0%;
        color: #000;
        font-family: "Caveat";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
      }
    }
  }

  .textD {
    padding-left: 0;
    margin-top: 25%;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 800;
      font-size: 88px;
      margin-bottom: 1rem;
      color: #ffffff;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      // line-height: 111px;
      color: #ffffff;
    }
  }

  .regDiv {
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .formDiv {
      // border-radius: 18px;
      background-color: #ffffff;
      width: 100%;
      height: auto;
      padding: 2rem;
      margin: 0%;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        // line-height: 30px;
        /* identical to box height */
        text-align: center;
        color: #0c4d6a;
      }

      .inputs {
        // margin-top: 2rem;

        .label-f {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 13px;
          /* identical to box height */
          color: #0c4d6a;
        }

        .textField {
          background-color: #fdfdfd;
          border: 1px solid #dfdbdb;
          border-radius: 5px;
          width: 100%;
          height: 50px;
          padding: 0 1rem;
        }

        .createBtn {
          background-color: #0c4d6a;
          width: 100%;
          height: 50px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 2rem;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
