<template>
  <div class="container-fluid">
    <div class="row">
      <div class="mt-10 col-lg-12">
        <div>
          <div class="my-4">
            <button style="color: #21618c" @click="gobacktoPage">
              <span style="margin: 0 1rem"
                ><i class="fas fa-arrow-left"></i
              ></span>
              Go Back
            </button>
          </div>
          <div class="chatPage">
            <div>
              <h6 class="name">{{ userName }} {{ userSurname }} Report</h6>
              <p class="date">{{ createdOn }}</p>
            </div>
            <div style="width: 100%">
              <div>
                <h6
                  class="my-2"
                  style="
                    color: #21618c;
                    font-weight: bold;
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                  "
                >
                  Goals for this period
                </h6>
                <div v-html="goals"></div>
              </div>
              <div>
                <h6
                  class="my-2"
                  style="
                    color: #21618c;
                    font-weight: bold;
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                  "
                >
                  My accomplishments
                </h6>
                <div v-html="accomplishment"></div>
              </div>
              <div>
                <h6
                  class="my-2"
                  style="
                    color: #21618c;
                    font-weight: bold;
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                  "
                >
                  How I worked with others
                </h6>
                <div v-html="workWithOthers"></div>
              </div>
              <div>
                <h6
                  class="my-2"
                  style="
                    color: #21618c;
                    font-weight: bold;
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: uppercase;
                  "
                >
                  Here are my Thoughts
                </h6>
                <div v-html="mythoughts"></div>
              </div>
            </div>
          </div>
          <hr style="color: #e8e5e5" />
          <h6 class="viewPrevious">View previous comments</h6>
          <div class="chatText">
            <div class="">
              <div
                class="messageTxt2"
                v-for="message in messages"
                :key="message.id"
              >
                <h6 class="SendrName">
                  <span>
                    <v-avatar color="blue" size="48">
                      <span class="white--text text-h5">{{
                        message.avatar
                      }}</span>
                    </v-avatar>
                    <span class="ml-4">
                      {{ message.user }}
                    </span>
                    <span style="font-size: 12px" class="ml-2">{{
                      message.reportdate
                    }}</span>
                  </span>
                  <div>
                    <button
                      class="mr-4 deleteBtn"
                      @click="replyTxtShw(message)"
                    >
                      <i class="fas fa-reply"></i>
                    </button>
                  </div>
                </h6>
                <p class="msgBody">{{ message.text }}</p>
                <div class="senderMsg2">
                  <div
                    id="chatBox2"
                    class="mb-8 messageTxt"
                    v-for="replymessage in replymessages2"
                    :key="replymessage.id"
                  >
                    <span v-if="message.remarkId == replymessage.remarkId">
                      <h6 class="SendrName">
                        <span>
                          <v-avatar color="orange" size="48">
                            <span class="white--text text-h5">{{
                              replymessage.avatar
                            }}</span>
                          </v-avatar>
                          <span class="ml-4">
                            {{ replymessage.user }}
                          </span>
                          <span style="font-size: 12px" class="ml-2">{{
                            replymessage.reportdate
                          }}</span>
                        </span>
                        <div>
                          <button
                            class="mr-4 deleteBtn"
                            @click="replyTxtShw2(replymessage)"
                          >
                            <i class="fas fa-reply"></i>
                          </button>
                        </div>
                      </h6>
                      <p class="msgBody">{{ replymessage.text }}</p>
                    </span>
                    <span v-else></span>
                  </div>
                </div>
                <div
                  v-for="replymessage in replymessages2"
                  :key="replymessage.id"
                >
                  <div v-if="replymessage.showreplyBox">
                    <p class="userreply">
                      <span style="margin-right: 8px; font-size: 20px"
                        ><i class="fas fa-reply-all"></i></span
                      >{{ replymessage.user }}
                    </p>
                    <span class="textreply">{{ replymessage.text }}</span>
                    <div id="message.remarkId" class="textBtn2">
                      <textarea
                        v-model="replytxt"
                        class="textArea"
                        id=""
                        placeholder="Comment/Remarks"
                        rows="1"
                      ></textarea>
                      <button
                        class="saveRemark"
                        @click="postRemarkreply2(replymessage)"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="message.showreplyBox">
                  <p class="userreply">
                    <span style="margin-right: 8px; font-size: 20px"
                      ><i class="fas fa-reply-all"></i></span
                    >{{ message.user }}
                  </p>
                  <span class="textreply">{{ message.text }}</span>
                  <div id="message.remarkId" class="textBtn2">
                    <textarea
                      v-model="replytxt"
                      class="textArea"
                      id=""
                      placeholder="Comment/Remarks"
                      rows="1"
                    ></textarea>
                    <button
                      class="saveRemark"
                      @click="postRemarkreply(message)"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sendRemark mt-10">
            <div class="textBtn">
              <textarea
                v-on:keyup.enter="sendMessage"
                v-model="bio"
                class="textArea"
                id="exampleFormControlTextarea1"
                placeholder="Comment/Remarks"
                rows="1"
              ></textarea>
              <button class="saveRemark" @click="saveRemark">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data: () => ({
    apiKey: process.env.VUE_APP_API_KEY,
    baseUrl: process.env.VUE_APP_SREP_BASE_URL,
    reponse: false,
    messages: [],
    replymessages: [],
    replymessages2: [],
    replymessages3: [],
    avata: 150,
    sent: false,
    recieved: false,
    edititemBtn: false,
    dialogRemark: false,
    commentdialog: false,
    itemId: "",
    replyValue: "",
    branch: "",
    formattedDate: "",
    replytxt: "",
    bio: "",
    userName: "",
    userSurname: "",
    createdBy: "",
    createdOn: "",
    goals: "",
    workWithOthers: "",
    accomplishment: "",
    mythoughts: "",
    userID: "",
    // messages2: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Remark By", value: "remarkBy" },
      { text: "Category", value: "category" },
      { text: "Report Submission Date", value: "reportdate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      reportdate: "",
      remarkBy: "",
      dateCreated: "",
      category: "",
    },
    defaultItem: {
      reportdate: "",
      remarkBy: "",
      dateCreated: "",
      category: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async beforeMount() {
    const retrievedData = localStorage.getItem("token");
    const retrievedItemId = localStorage.getItem("IdItem");
    const retrieveduserId = localStorage.getItem("userID");
    const token = JSON.parse(retrievedData);
    const item = JSON.parse(retrievedItemId);
    const user = JSON.parse(retrieveduserId);
    this.getremarks();
    this.getreplyremarks();
    this.getreplyremarks2();

    await axios
      .all([
        axios.get(`${this.baseUrl}userreport/getreviewone/${item}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        }),
        axios.get(`${this.baseUrl}users/User/${user}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        }),
      ])
      .then(
        axios.spread((response1, response2) => {
          const res = response1.data.data;
          this.goals = res.goals;
          this.itemId = res._id;
          this.workWithOthers = res.workingWithothers;
          this.accomplishment = res.accomplishment;
          this.mythoughts = res.mythoughts;
          this.createdOn = res.createdAt.split("T")[0];
          this.userID = response2.data.user._id;
          this.userName =
            response2.data.user.firstName + " " + response2.data.user.lastName;
          this.branch = response2.data.user.branch;
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    replyTxtShw(message) {
      message.showreplyBox = !message.showreplyBox;
    },
    replyTxtShw2(replymessage) {
      replymessage.showreplyBox = !replymessage.showreplyBox;
    },

    // DELETE REPLY
    async deleteReply(replymessage) {
      const itemId = replymessage.replyremarkId;
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      await axios
        .delete(`${this.baseUrl}userReply/deletereplies/${itemId}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.getreplyremarks();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // DELETE REMARK
    async deleteRemark(message) {
      console.log(message);
      const itemId = message.remarkId;
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      await axios
        .delete(`${this.baseUrl}adminRemark/remark/${itemId}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.getreplyremarks();
          this.deleteReply();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getremarks() {
      const retrievedItemId = localStorage.getItem("IdItem");
      const item = JSON.parse(retrievedItemId);
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      await axios
        .get(`${this.baseUrl}adminRemark/remark/${item}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const messages = [];
          response.data.data.remarks.forEach((elem) => {
            messages.push({
              avatar: elem.remarkBy.lastName[0],
              remarkId: elem._id,
              text: elem.comment,
              replybtn: elem.replybutton,
              user: elem.remarkBy.firstName + " " + elem.remarkBy.lastName,
              reportdate: elem.date.split("T")[0],
              replyofRemark: elem.replyofRemark,
              showreplyBox: false,
              showreplyBoxrrr: false,
            });
          });
          this.messages = messages;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getreplyremarks() {
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      await axios
        .get(`${this.baseUrl}userReply/reply`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const replymessages2 = [];
          response.data.data.replys.forEach((elem) => {
            console.log(elem);
            replymessages2.push({
              avatar: elem.replyBy.lastName[0],
              remarkId: elem.remarkId._id,
              replyremarkId: elem._id,
              text: elem.reply,
              user: elem.replyBy.firstName + " " + elem.replyBy.lastName,
              reportdate: elem.createdAt.split("T")[0],
              showreplyBox: false,
              showreplyBoxrrr: false,
            });
          });
          this.replymessages2 = replymessages2;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getreplyremarks2() {
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      await axios
        .get(`${this.baseUrl}admincommentReply/commentreply`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const replymessages3 = [];
          response.data.data.replys.forEach((elem) => {
            replymessages3.push({
              replyremarkId: elem._id,
              text: elem.commentReply,
              user: elem.replyByid.firstName + " " + elem.replyByid.lastName,
              reportdate: elem.createdAt.split("T")[0],
            });
          });
          this.replymessages3 = replymessages3;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async postRemarkreply(message) {
      const remarkId = message.remarkId;
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      if (this.replytxt === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Comment is Empty",
          width: 350,
        });
      } else {
        await axios
          .post(
            `${this.baseUrl}userReply/replies/${remarkId}`,
            {
              reply: this.replytxt,
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.replytxt = "";
            this.getreplyremarks();
            message.showreplyBox = false;
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong Please Try again.",
              width: 350,
            });
          });
      }
    },
    async postRemarkreply2(replymessage) {
      const remarkId = replymessage.remarkId;
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      if (this.replytxt === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Comment is Empty",
          width: 350,
        });
      } else {
        // console.log(this.userID);
        await axios
          .post(
            `${this.baseUrl}userReply/replies/${remarkId}`,
            {
              reply: this.replytxt,
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.replytxt = "";
            this.getreplyremarks();
            replymessage.showreplyBox = false;
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong Please Try again.",
              width: 350,
            });
          });
      }
    },
    gobacktoPage() {
      // setTimeout(() => {
      this.$router.push("/createReport");
      // }, 1200);
    },
    sendMessage() {
      this.addMessage();
      this.bio = "";
    },
    addMessage() {
      const message = {
        id: new Date().getTime(),
        text: this.bio,
        user: this.userName,
      };
      this.messages = this.messages.concat(message);
      this.socketInstance.emit("message", message);
    },
    opencommentD(item) {
      console.log(item);
      this.commentdialog = true;
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRemark = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    async saveRemark() {
      const reportId = this.itemId;
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      // const decoded = jwt_decode(token);
      // const RuserId = decoded.id
      if (this.bio === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Comment is Empty",
          width: 350,
        });
      } else {
        // console.log(this.userID);
        await axios
          .post(
            `${this.baseUrl}adminRemark/remarks/${reportId}`,
            {
              comment: this.bio,
              replybutton: "Reply",
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.getremarks();
            this.bio = "";
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong Please Try again.",
              width: 350,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Caveat");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap");

$color-base: #fff;
$color-text: #222;
$color-accent: desaturate(navy, 80%);

.chatPage {
  box-sizing: border-box;
  width: 100%;
  height: 395px;
  background: #ffffff;
  border: 1px solid #e8edf0;
  border-radius: 4px;
  padding: 31px;
  overflow-y: scroll;

  .name {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: #6c6c6c;
  }

  .date {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    color: #9f9f9f;
  }
}

.chatPage::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 5px;
}

.chatPage::-webkit-scrollbar-track {
  background: transparent;
}

.chatPage::-webkit-scrollbar-thumb {
  background-color: #21618c;
  border-right: none;
  border-left: none;
}

.chatPage::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.chatPage::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.senderMsg2 {
  // margin-left: 4rem;
  margin-bottom: 2rem;
  // margin-top: 1rem;
}

.textBtn {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e8edf0;
  border-radius: 4px;
  align-items: center;

  .textArea {
    padding: 1rem;
    background-color: #ffffff;
    width: 100%;

    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .saveRemark {
    width: 76px;
    height: 36px;
    background: #f03c3c;
    border-radius: 4px;
    margin-right: 10px;

    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #ffffff;
  }
}

.textBtn2 {
  background-color: #ffffff;
  width: auto;
  // margin-left: 4rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e8edf0;
  border-radius: 4px;
  align-items: center;

  .textArea {
    padding: 1rem;
    background-color: #ffffff;
    width: 100%;

    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .saveRemark {
    width: 76px;
    height: 36px;
    background: #f03c3c;
    border-radius: 4px;
    margin-right: 10px;

    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #ffffff;
  }
}

.viewPrevious {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #f03c3c;
}

.chatText {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  // background: #FFFFFF;
  // border: 1px solid #E8EDF0;
  border-radius: 4px;
  padding: 31px;

  .messageTxt {
    width: 100%;

    .SendrName {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      // line-height: 20px;
      /* identical to box height, or 154% */
      color: #6c6c6c;
      // display: flex;
      // justify-content: center;
    }

    .msgBody {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      padding-bottom: 0%;
      margin-bottom: 0%;
      // line-height: 20px;
      /* or 154% */
      width: 100%;
      margin-left: 4.5rem;
      color: #6c6c6c;
    }
  }

  .messageTxt2 {
    margin: 1rem 0;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid #e8edf0;
    border-radius: 4px;
    padding: 31px;

    .SendrName {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      // line-height: 20px;
      /* identical to box height, or 154% */
      color: #6c6c6c;
      margin: 0%;
      padding: 0%;
      display: flex;
      justify-content: space-between;

      .deleteBtn {
        color: lightgray;

        &:hover {
          color: gray;
        }
      }
    }

    .msgBody {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      width: 95%;
      // line-height: 20px;
      /* or 154% */
      margin-left: 4.5rem;
      color: #6c6c6c;
    }

    .userreply {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      padding: 0%;
      margin: 0%;
      // margin-left: 20px;
      color: #6c6c6c;
    }

    .textreply {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      font-style: italic;
      // padding: 0%;
      // margin: 0%;
      // margin-left: 30px;
      color: #6c6c6c;
    }

    .textreply2 {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      // padding: 0%;
      padding-left: 2.7rem;
      margin-left: 30px;
      color: #6c6c6c;
    }
  }
}

.replybtn {
  margin-bottom: 30px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #f03c3c;
  margin-left: 4.5rem;
}

.sendRemark {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-fluid {
  height: 100vh;
  padding-top: 2rem;
  padding-left: 5rem;
  padding-bottom: 5rem;
}

.newR2 {
  width: auto;
  height: auto;
  padding: 0.5rem 3rem;
  margin-left: 2rem;
  background: #21618c;
  // border-radius: 3px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.sendRequest2 {
  // margin-right: 50px;
  // border-radius: 8px;
  padding: 10px 25px;
  margin-top: 2rem;
  background: #21618c;
  // border-radius: 3px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.cancl {
  width: auto;
  height: auto;
  padding: 0.5rem 3rem;
  margin-left: 2rem;
  background: grey;
  // border-radius: 3px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
</style>
