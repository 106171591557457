<template>
  <div class="container-fluid">
    <div class="row">
      <div class="mt-10 col-lg-12">
        <div class="tabTitle">
          <h4 class="ml-2">Settings</h4>
          <v-data-table
            :headers="headers"
            :items="desserts"
            sort-by="calories"
            :items-per-page="5"
            class=""
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <button class="ml-8 newR" v-bind="attrs" v-on="on">
                      Add Settings
                    </button>
                  </template>
                  <v-card>
                    <v-card-title
                      style="
                        background-color: #21618c;
                        color: #fff;
                        margin-bottom: 1rem;
                      "
                    >
                      <span style="font-size: 20px">{{ formTitle }}</span>
                    </v-card-title>
                    <div class="pa-4">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.date"
                            label="Select the Deadline Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                      <!-- <v-text-field
                        v-model="editedItem.frequency"
                        label="Number of reminder"
                        clearable
                      >
                      </v-text-field> -->

                      <v-select
                        v-model="editedItem.favorites"
                        :items="states"
                        label="Pick Dates for the Reminder"
                        multiple
                      ></v-select>
                      <v-textarea
                        solo
                        name="input-7-4"
                        v-model="editedItem.message"
                        label="Message"
                      >
                      </v-textarea>

                      <!-- <button v-if="desserts.length > 0" class="saveRemarkDis">
                        Save Settings
                      </button> -->
                      <button class="saveRemark" @click="save()">
                        Save Settings
                      </button>
                    </div>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="textDel"
                      >Are you sure you want to delete this Settings?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
/* eslint-disable */
export default {
  data: () => ({
    apiKey: process.env.VUE_APP_API_KEY,
    baseUrl: process.env.VUE_APP_SREP_BASE_URL,
    activePicker: null,
    date: null,
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    dialog: false,
    dialogDelete: false,
    year: "",
    month: "",
    favorites: [],
    states: [],
    headers: [
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
        class: "blue-grey lighten-4",
      },
      { text: "Last Name", value: "lastName", class: "blue-grey lighten-4" },
      { text: "Email", value: "email", class: "blue-grey lighten-4" },
      { text: "Level", value: "level", class: "blue-grey lighten-4" },
      { text: "Deadline", value: "date", class: "blue-grey lighten-4" },
      { text: "Frequncy", value: "frequency", class: "blue-grey lighten-4" },
      // { text: 'Message', value: 'message' },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "blue-grey lighten-4",
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      email: "",
      level: "",
      settingsId: "",
      SetById: "",
      date: null,
      message: "",
      frequency: "",
      favorites: [],
    },
    defaultItem: {
      date: null,
      message: "",
      favorites: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Settings" : "Edit Settings";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async beforeMount() {
    const currentDate = new Date();
    this.month = currentDate.getMonth();
    this.year = currentDate.getFullYear();

    const daysInMonth = this.getDaysInMonth(this.year, this.month);
    this.states.push(...daysInMonth);

    window.addEventListener("scroll", this.handleScroll);
    const retrievedData = localStorage.getItem("token");
    const token = JSON.parse(retrievedData);

    await axios
      .all([
        axios.get(`${this.baseUrl}settings/settings`, {
          headers: {
            token: `Bearer ${token}`,
          },
        }),
      ])
      .then(
        axios.spread((response1) => {
          this.request = response1.data.data.nbHits;
          response1.data.data.settings.forEach((elem) => {
            this.desserts.push({
              settingsId: elem._id,
              SetById: elem.SetBy._id,
              firstName: elem.SetBy.firstName,
              lastName: elem.SetBy.lastName,
              email: elem.SetBy.email,
              level: elem.SetBy.level,
              date: elem.day,
              frequency: elem.numberOfreminder,
              message: elem.message,
            });
          });
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    getDaysInMonth(year, month) {
      // Month in JavaScript is 0-indexed (0 for January, 11 for December)
      // const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0).getDate();
      const daysArray = [];
      for (let day = 1; day <= lastDay; day++) {
        daysArray.push(day);
      }
      return daysArray;
    },
    savedate(date) {
      this.$refs.menu.save(date);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      const decoded = jwt_decode(token);
      this.userid = decoded.id;
      const itemId = this.editedItem.settingsId;
      console.log(itemId);
      await axios
        .delete(`${this.baseUrl}settings/delete/settings/${itemId}`, {
          headers: {
            token: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.desserts.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error);
        });
      // LOGGING
      await axios
        .post(
          `${this.baseUrl}logRoute/newlog/${this.userid}`,
          {
            activity: `Deleted Settings`,
          },
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const retrievedData = localStorage.getItem("token");
      const token = JSON.parse(retrievedData);
      const decoded = jwt_decode(token);
      this.userid = decoded.id;
      const settingsId = this.editedItem.settingsId;
      if (this.editedIndex > -1) {
        await axios
          .patch(
            `${this.baseUrl}settings/newsetting/${settingsId}`,
            {
              day: this.editedItem.date,
              numberOfreminder: this.editedItem.favorites,
              message: this.editedItem.message,
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            Object.assign(this.desserts[this.editedIndex], this.editedItem);
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              text: "Settings Updated",
              width: 350,
              timer: 1000,
            });
            this.close();
          })
          .catch((error) => {
            console.log(error);
          });

        // LOGGING
        await axios
          .post(
            `${this.baseUrl}logRoute/newlog/${this.userid}`,
            {
              activity: `Edited Settings`,
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await axios
          .post(
            `${this.baseUrl}settings/newsetting/${this.userid}`,
            {
              day: this.editedItem.date,
              numberOfreminder: this.editedItem.favorites,
              message: this.editedItem.message,
              record: true,
              startday: new Date(),
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            const item = response.data.data.setting;
            this.editedItem = {
              settingsId: item._id,
              SetById: item.SetBy._id,
              firstName: item.SetBy.firstName,
              lastName: item.SetBy.lastName,
              email: item.SetBy.email,
              level: item.SetBy.level,
              date: item.day,
              frequency: item.numberOfreminder,
              message: item.message,
            };
            this.desserts.push(this.editedItem);
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              text: "Settings Saved",
              width: 350,
              timer: 1000,
            });
            this.date = "";
            this.frequency = "";
            this.message = "";
            this.close();
          })
          .catch((error) => {
            console.log(error);
          });

        // LOGGING
        await axios
          .post(
            `${this.baseUrl}logRoute/newlog/${this.userid}`,
            {
              activity: `Created Settings`,
            },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  height: 100vh;
  padding-top: 5rem;
  padding-left: 5rem;
  padding-bottom: 5rem;
  background: #e5e5e5;
  height: 100vh;
}

.tabTitle {
  width: 100%;
  padding: 20px 0;
  height: auto;
  background: #ffffff;
  border-radius: 8px;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #4f4f4f;
  }
}

.newR {
  width: auto;
  height: auto;
  padding: 0.5rem;
  background: #21618c;
  // border-radius: 3px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.saveRemark {
  width: 45%;
  height: 36px;
  background: #21618c;
  border-radius: 4px;
  margin-right: 10px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #ffffff;
}
.saveRemarkDis {
  width: 45%;
  height: 36px;
  background: grey;
  border-radius: 4px;
  margin-right: 10px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #ffffff;
}

.textDel {
  margin-bottom: 0.8rem;
  color: #21618c;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .container-fluid {
    height: 100vh;
    padding-top: 5rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }
}
</style>
