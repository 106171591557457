<template>
  <div>
    <div style="height: 100vh" class="container-fluid">
      <div class="row no-gutters">
        <div class="col-lg-5">
          <div class="textD">
            <h1 class="srep">SREP</h1>
            <p>Appmart Staff Report.</p>
          </div>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
          <div class="regDiv">
            <div class="formDiv">
              <h3>Sign In</h3>
              <div class="inputs">
                <div class="">
                  <label class="label-f">Email address</label>
                  <v-text-field
                    outlined
                    placeholder="Email Address"
                    v-model="email"
                    :rules="[emailrules.required, emailrules.email]"
                  >
                  </v-text-field>
                  <!-- <input v-model="email" type="email" class="textField" id=""> -->
                </div>
                <div class="">
                  <label class="label-f">Password</label>
                  <v-text-field
                    outlined
                    placeholder="Password"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    hint=""
                    v-model="password"
                    value="password"
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                  >
                  </v-text-field>
                  <!-- <input v-model="password" type="password" class="textField" id=""> -->
                </div>
                <div class="mb-3">
                  <button @click="forgotPassword" class="signIn">
                    Forgot password?
                  </button>
                </div>
                <div class="mt-3">
                  <button @click="login" class="createBtn">Log In</button>
                </div>
                <!-- <div class="mb-3">
                                    <button @click="forgotPass" class="signIn">Forgot Password?</button>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      baseUrl: process.env.VUE_APP_SREP_BASE_URL,
      email: "",
      password: "",
      userid: "",
      userName: "",
      show3: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      emailrules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    forgotPassword() {
      this.$router.push("/forgotPassword");
    },
    async login() {
      if (!this.email || !this.password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email and Password Needed",
          width: 350,
        });
      } else {
        const logindata = {
          email: this.email,
          password: this.password,
        };
        await axios
          .post(`${this.baseUrl}auth/login`, logindata, {
            headers: {
              api_key: this.apiKey,
            },
          })
          .then((response) => {
            const loginCount = response.data.data.loginCount; //Checks if the user have logged in Before
            Swal.fire({
              icon: "success",
              title: "Proceed",
              showConfirmButton: false,
              text: "Login Successful",
              width: 350,
              timer: 1000,
            });
            this.email = "";
            this.password = "";
            const token = response.data.data.accessToken;
            localStorage.setItem("token", JSON.stringify(token));
            const decoded = jwt_decode(token);
            this.userid = decoded.id;
            this.userName = decoded.lastName;
            if (loginCount === 0) {
              Swal.fire({
                icon: "info",
                title: "Proceed",
                showConfirmButton: false,
                text: "Please Change your Password",
                width: 350,
                timer: 1000,
              });
              setTimeout(() => {
                this.$router.push("/changePassword");
              }, 1200);
            } else if (decoded.category === "Admin") {
              setTimeout(() => {
                this.$router.push("/dashboardadmin");
              }, 1200);
            } else {
              setTimeout(() => {
                this.$router.push("/createReport");
              }, 1200);
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
              width: 350,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Caveat");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap");

* {
  margin: 0%;
  padding: 0%;
}

.container-fluid {
  background: url("../../assets/dashboard svg.svg");
  background-size: cover;
  width: 100%;
}

.lightLogo {
  display: block;
}

.darkLogo {
  display: none;
}

.logoDiv {
  position: fixed;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;

  .logoTxt {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    justify-content: center;
    align-items: flex-end;

    h4 {
      margin: 0%;
      color: #fff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
    }

    p {
      margin: 0%;
      color: #fff;
      font-family: "Caveat";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
    }
  }
}

.textD {
  padding-left: 5rem;
  margin: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    font-family: "Rubik";
    font-style: normal;
    line-height: 76px;
    font-weight: 800;
    font-size: 108px;
    // margin-bottom: 2rem;
    color: #ffffff;
  }

  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    // line-height: 111px;
    color: #ffffff;
  }
}

.regDiv {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  .formDiv {
    border-radius: 8px;
    background-color: #ffffff;
    width: 80%;
    height: auto;
    padding: 2rem;
    margin: 0%;

    h3 {
      margin-bottom: 30px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      // line-height: 30px;
      /* identical to box height */
      text-align: center;
      color: #0c4d6a;
    }

    .inputs {
      // margin-top: 2rem;

      .label-f {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        margin-bottom: 14px;
        /* identical to box height */
        color: #0c4d6a;
      }

      .textField {
        background-color: #fdfdfd;
        border: 1px solid #dfdbdb;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        padding: 0 1rem;
      }

      .createBtn {
        border-radius: 5px;
        background-color: #0c4d6a;
        width: 40%;
        height: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 2rem;
        color: #ffffff;
      }

      .signIn {
        color: #0c4d6a;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        margin-left: 2px;
      }

      .alrdy {
        color: grey;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1200px) {
  .lightLogo {
    display: none;
  }

  .darkLogo {
    display: block;
    width: 20%;
  }

  .logoDiv {
    width: 100%;
    position: fixed;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: #fdfdfd;
    margin: 0%;

    .logoTxt {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      justify-content: center;
      align-items: flex-end;

      h4 {
        margin: 0%;
        color: #000;
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
      }

      p {
        margin: 0%;
        color: #000;
        font-family: "Caveat";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
      }
    }
  }

  .textD {
    padding-left: 0;
    margin-top: 25%;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 800;
      font-size: 88px;
      margin-bottom: 1rem;
      color: #ffffff;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      // line-height: 111px;
      color: #ffffff;
    }
  }

  .regDiv {
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .formDiv {
      // border-radius: 18px;
      background-color: #ffffff;
      width: 100%;
      height: auto;
      padding: 2rem;
      margin: 0%;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        // line-height: 30px;
        /* identical to box height */
        text-align: center;
        color: #0c4d6a;
      }

      .inputs {
        // margin-top: 2rem;

        .label-f {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 13px;
          /* identical to box height */
          color: #0c4d6a;
        }

        .textField {
          background-color: #fdfdfd;
          border: 1px solid #dfdbdb;
          border-radius: 5px;
          width: 100%;
          height: 50px;
          padding: 0 1rem;
        }

        .createBtn {
          background-color: #0c4d6a;
          width: 100%;
          height: 50px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 2rem;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
